.withdrawal-proposal {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  position: relative;

  h3 {
    font-size: 0.875em;
    font-weight: 400;
  }

  &__subtext {
    color: var(--grey-300);
    font-size: 0.75em;
    padding-left: 1em;

    span {
      color: var(--grey-500);
      font-weight: 700;
    }
  }

  &__fields {
    width: 100%;
    display: grid;
    grid-template-columns: 75% 25%;
  }
}
