.proposal-modal-header {
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.5em;

    div {
      display: flex;
      align-items: center;
      gap: 0.5em;

      span {
        font-size: 0.75em;
      }
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding-bottom: 1.5em;

    p {
      font-size: 0.875em;
      color: var(--grey-300);
    }
  }
}
