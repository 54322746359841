.proposal-description {
  padding: 1.5em;
  background-color: var(--white-100);
  border-radius: 0.75em;
  border: 1px solid var(--grey-016);

  &__flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    h2 {
      font-size: 1em;
    }

    img {
      padding: 0.5em;
      border: 1px solid var(--grey-500);
      border-radius: 50%;
      cursor: pointer;
    }
  }

  p {
    font-size: 0.875em;
    color: var(--grey-300);
    padding-right: 6em;
  }

  ul {
    position: absolute;
    right: 3.5em;
    background-color: white;
    border-radius: 8px;
    box-shadow: 10px 10px 40px var(--grey-008);
    border: 1px solid var(--grey-016);
    margin-top: 0.5em;
    z-index: var(--z-10);

    li {
      padding: 0.5em 1em;
      color: var(--grey-300);
      cursor: pointer;
      transition: all 0.1s ease-in-out;

      &:hover {
        background-color: var(--white-100);
      }
    }
  }
}
