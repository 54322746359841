.preview-deal {
  width: 100%;
  padding: 5em 0;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;

    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 50%;
    }

    p {
      font-size: 0.85em;
    }
  }

  &__verticals {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25em;
    p {
      font-size: 0.8em;
      font-weight: bold;
      color: var(--grey-500);
    }
  }

  &__section {
    margin: 2.5em 0;
  }
}
