.staking-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em;
  border-radius: 12px;
  background-color: var(--grey-008);
  margin-bottom: 0.5em;

  &__deposit {
    display: flex;
    align-items: center;
    gap: 1em;

    p {
      color: var(--grey-300);
    }
  }

  &__reward {
    display: flex;
    justify-content: space-between;
    width: 22%;
  }
}
