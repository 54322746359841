.browse-deals-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 342px;
  min-height: 100%;
  background-color: white;
  border: 1px solid var(--grey-008);
  border-radius: 1em;
  color: var(--grey-500);
  transition: all 0.15s ease-out;
  cursor: pointer;

  &__badge {
    position: absolute;
    right: 10px;
    top: 13px;
  }

  img {
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    object-fit: cover;
    aspect-ratio: 1;
    width: 100%;
  }

  button {
    position: absolute;
    top: 60%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 0.75em 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    z-index: var(--z-10);
  }

  &__content {
    padding: 1.5em;

    &-title {
      font-size: 1em;
      font-weight: 700;
      padding-bottom: 0.5em;
    }

    p {
      font-size: 0.75em;
      font-weight: 400;

      span {
        font-size: 12px;
        font-weight: 700;
      }
    }
  }

  &__tags {
    padding-top: 1.5em;
    display: flex;
    flex-wrap: wrap;
    gap: 0.25em;
    color: var(--grey-300);
    font-size: 0.875em;

    p:not(:last-child)::after {
      content: ",";
    }
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 18px 50px -10px;
  }
}

button.invest-cta {
  color: var(--grey-500);
  background-color: var(--primary-color);
  border: none;
  font-size: 0.875em;

  &:hover {
    background-color: var();
  }
}

button.pitchdeck-cta {
  color: white;
  background-color: var(--grey-500);
  border: none;
  font-size: 0.875em;

  &:hover {
    background-color: var();
  }
}
