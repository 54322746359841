.intro-deck-header {
  display: flex;
  gap: 2em;
  align-items: flex-start;
  justify-content: flex-start;

  h2 {
    font-size: 2em;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    //   width: 70vw;
    padding-bottom: 2em;

    &-buttons {
      display: flex;
      gap: 0.5em;
    }
  }

  &__main {
    display: flex;
    gap: 2em;
    align-items: center;
    padding-bottom: 3.5em;
  }

  &__unique {
    //   width: 10%;
  }

  &__list {
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--white-300);
    padding-right: 2em;
    gap: 0.5em;
    width: 100%;

    &-items {
      display: flex;
      gap: 0.5em;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      padding: 0.5em 1em;
      border-radius: 12px;
      width: 100%;

      span {
        font-weight: 700;
      }
    }
  }
}
