.trade-approval-config {
  &__fields {
    position: relative;
    display: flex;
    padding: 0.35em;

    img {
      position: absolute;
      top: -7px;
      right: -10px;
      z-index: var(--z-10);
      background-color: white;
      padding: 2px;
      cursor: pointer;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-around;
  }
}
