.transfer-funds-proposal {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  position: relative;

  p {
    font-size: 0.875em;
  }

  &__amount-and-token {
    width: 100%;
    display: grid;
    grid-template-columns: 75% 25%;
  }
  &__subtext {
    color: var(--grey-300);
    font-size: 0.75em;
    padding-left: 1em;
    margin-bottom: 2em;

    span {
      color: var(--grey-500);
      font-weight: 700;
    }
  }
}
