.fundraise-progress {
  display: flex;

  align-items: center;
  font-size: 0.87em;
  gap: 0.5em;

  &__actions {
    display: flex;
    align-items: start;
  }
  &__raised,
  &__cap,
  &__deposit {
    display: flex;
    flex-direction: column;
    width: 12em;
    &-amount {
      font-weight: bold;
      display: flex;
      gap: 0.2em;
    }
    &-currency {
      color: var(--grey-200);
    }
  }
  &__deposit {
    text-align: end;
  }

  &__deposit-amount {
    justify-content: end;
  }
}
