.fundraise-info {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  display: flex;
  justify-content: space-between;
  &__totaly,
  &__goal {
    font-size: 0.7em;
    color: var(--grey-200);
    display: flex;
    flex-direction: column;
  }

  &__totaly-amount,
  &__goal-amount {
    font-weight: bold;
    font-size: 1.25em;
  }

  &__totaly-currency,
  &__goal-currency {
    color: var(--grey-200);
  }

  &__deposit-info {
    display: flex;
    flex-direction: column;
    &-amount {
      font-weight: bold;

      &-currency {
        color: var(--grey-200);
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }
}
