.token-unlocking-date {
  display: flex;
  align-items: center;
  position: relative;
  text-align: end;
  width: 12em;
  justify-content: end;

  &:hover {
    .token-unlocking-date__reward-tokens {
      visibility: visible;
      z-index: 30;
      border: 1px solid var(--light-background-color);
      border-radius: 12px;
    }
  }

  &__reward {
    &-tokens {
      background-color: white;
      position: absolute;
      bottom: 100%;
      visibility: hidden;
    }

    &-container {
      display: flex;
      flex-direction: column;
    }
    &-amount {
      justify-content: end;
      font-weight: bold;
      display: flex;
      gap: 0.2em;
      align-items: center;
      &-currency {
        color: var(--grey-200);
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      gap: 0.5em;
    }
  }

  &__table {
    font-size: 0.75em;
    width: 18.75em;
    padding: 1em;
    border-radius: 12px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5em;
  }

  &__amount {
    font-weight: bold;
  }

  &__date {
    font-weight: 400;
  }
}
