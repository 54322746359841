.roles-config {
  width: 100%;
  margin-top: 6em;

  &__title {
    font-weight: bold;
    font-size: 0.8em;
    color: var(--grey-500);
  }

  &__voting-power {
    display: flex;
    width: 100%;
  }

  &__role {
    width: 50%;

    .MuiInputBase-root {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
  }

  &__power {
    width: 50%;
    .MuiInputBase-root {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }
  }

  &__section {
    margin-bottom: 1em;
  }
}
