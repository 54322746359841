.fundraise-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em 0;

  &__container {
    display: flex;
  }

  &__fundraise-tab,
  &__history-tab,
  &__tokens-tab,
  &__members-tab {
    color: var(--grey-200);
    font-size: 1em;
    cursor: pointer;
    font-size: 0.9em;
    margin-right: 0.5em;
    &--open {
      color: var(--grey-500);
      font-weight: bold;
      transition: all 0.15s ease-in;
    }
  }
}
