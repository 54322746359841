.overview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
  padding: 2em 2em 4em;
  background-color: white;
  width: 100%;

  &__details {
    display: flex;
    gap: 3em;

    h2 {
      padding: 0;
    }

    &-image {
      position: relative;

      &-profile {
        width: 144px;
        height: 144px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  &__edit-pen {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    padding: 0.5em;
    background-color: white;
    border-radius: 50%;
    border: 1px solid var(--white-300);
    cursor: pointer;
  }

  &-basic-details {
    display: flex;
    flex-direction: column;
    gap: 1em;
    cursor: default;

    h2 {
      font-size: 1.5em;
      color: var(--grey-500);
      flex-wrap: wrap;
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    p {
      font-size: 1em;
      color: var(--grey-300);
      flex-wrap: wrap;
      align-self: stretch;
    }

    .tags {
      display: flex;
      gap: 0.5em;
      padding-top: 0.5em;

      p {
        color: var(--grey-500);
      }
    }
  }

  &__options {
    display: flex;
    align-self: flex-start;
    gap: 3em;
    flex-direction: column;

    &-actions {
      display: flex;
      justify-content: flex-end;
      gap: 1em;
      align-items: center;
    }

    &-treasury-details {
      display: flex;
      justify-content: flex-end;
      gap: 1.5em;

      h4 {
        font-size: 0.75em;
        color: var(--grey-300);
        font-weight: 400;
      }

      p {
        font-size: 1.25em;
        font-weight: 700;
        color: var(--grey-500);
      }

      span {
        color: var(--grey-200);
      }
    }
  }
}
