.proposal-modal-progress {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: 1em;
  padding-bottom: 1.5em;
  border-bottom: 1px solid var(--grey-100);

  &__bar {
    width: inherit;
    height: 4px;
    box-sizing: content-box;
    position: relative;
    background: var(--grey-100);
    border-radius: 0.5em;
    box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);

    &--green {
      max-width: 100%;
      display: block;
      height: 100%;
      border-radius: 0.5em;
      background-color: var(--primary-color);
      position: relative;
      overflow: hidden;
    }

    &--red {
      max-width: 100%;
      display: block;
      height: 100%;
      border-radius: 0.5em;
      background-color: var(--primary-color);
      position: relative;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      background-color: var(--red-500);
    }

    &-circle {
      max-width: 100%;
      width: 100%;
      display: block;
      height: 32px;
      border-radius: 0.5em;
      overflow: hidden;

      span {
        font-size: 0.75em;
        background-color: var(--white-300);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        position: absolute;
        z-index: var(--z-10);
        height: 32px;
        top: -1.25em;
      }
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
    }

    &-results {
      display: flex;
      width: 100%;
      justify-content: space-between;

      p {
        font-size: 0.75em;
      }
    }
  }
}
