.basic-deal-info {
  margin-top: 5em;

  &__upload-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }

  &__image-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--grey-100);
    border-radius: 50%;
    cursor: pointer;
    width: 100px;
    height: 100px;
    position: relative;
  }

  &__avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  &__removeIcon {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  &__photo-data {
    color: var(--grey-200);
    font-size: 0.8em;
    line-height: 22px;
    text-align: center;
  }

  &__label {
    font-size: 0.8em;
    font-weight: bold;
    color: var(--grey-500);
  }

  &__verticals {
    display: flex;
    flex-direction: column;
    grid-auto-flow: 1em;
    align-items: flex-start;
    margin-top: 2em;
    gap: 1em;

    width: 70%;

    p {
      font-size: 0.8em;
      font-weight: bold;
      color: var(--grey-500);
    }
    div {
      display: flex;
      flex-wrap: wrap;
      gap: 0.25em;
    }
  }

  &__data {
    width: 100%;
  }
  &__name-description {
    width: 100%;
  }
}
