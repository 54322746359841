.member-application {
  margin-top: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.3em;
  }

  &__name {
    display: flex;
    font-weight: bold;
  }

  &__social-networks {
    display: flex;
    gap: 0.2em;
    img {
      width: 16px;
      height: 13px;
    }
  }

  &__wallet {
    display: flex;
    color: var(--grey-200);
    font-size: 0.75em;
    font-weight: 700;

    &-copy {
      cursor: pointer;
    }
  }

  &__motivation-letter {
    font-size: 0.9em;
    color: var(--grey-300);
    font-weight: 400;
    text-align: justify;
  }

  &__roles {
    margin: 1em 0;
  }

  &__header {
    font-weight: 700;
    font-size: 0.875em;
    margin-bottom: 0.5em;
  }

  &__treasury-info {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    border: 1px solid #e9e9e9;
    align-items: center;

    &__treasury-name {
      font-size: 0.875em;
      color: var(--grey-500);
    }

    &:nth-of-type(1) {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    &:last-of-type {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }

  &__treasury-title {
    font-size: 0.875em;
    color: var(--grey-500);
  }

  &__treasury-data {
    display: flex;
    justify-content: space-between;
    gap: 1.5em;
    font-size: 0.75em;
    width: 25%;
  }

  &__treasury-column {
    display: flex;
    flex-direction: column;
    color: var(--grey-300);

    &__bold {
      font-weight: 700;
      color: var(--grey-500);

      span {
        color: var(--grey-200);
      }
    }
  }

  &__treasuries {
    display: flex;
    flex-direction: column;
    button {
      margin-top: 1em;
      align-self: flex-end;
    }
  }
}
