.manage-treasury {
  margin: 1em 0;

  &__description {
    font-size: 0.875em;
    color: var(--grey-300);
  }
  &__members {
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 0.5em;
    img {
      position: absolute;
      top: -7px;
      right: -10px;
      z-index: 10;
      background-color: white;
      padding: 2px;
      cursor: pointer;
    }

    &-wallet {
      width: 65%;

      .MuiInputBase-root {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
    }

    &-role {
      width: 35%;
      .MuiInputBase-root {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
      }
    }
  }
  &__actions {
    display: flex;
    justify-content: end;
  }
}
