.proposal-modal-details {
  display: flex;
  flex-direction: column;
  font-size: 0.875em;
  gap: 0.5em;
  padding: 1.5em 0;

  &__description {
    color: var(--grey-300);
  }
  &__additional-info {
    display: flex;
    justify-content: space-between;

    span {
      font-weight: 700;
    }
  }
  &__roles {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &-item {
      font-weight: 700;
    }
  }

  &__link {
    display: flex;
    gap: 4px;
    border-bottom: 1px solid var(--grey-100);

    a {
      color: var(--blue-500);
      font-weight: 400;
    }
  }
}
