.token,
.token__info,
.token__logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.token {
  margin-top: 1em;
  padding: 1em 1.2em;
  border-radius: 14px;
  background-color: var(--white-200);
  border: 1px solid #24242414;
  width: 18em;
  margin-right: 0.5em;

  &__amount {
    font-weight: bold;
    &-currency {
      color: var(--grey-200);
    }
  }

  &__logo {
    margin-right: 0.6em;
  }
  &__name {
    font-size: 0.9em;
  }
  img {
    width: 25px;
    height: 25px;
    border-radius: 25px;
  }
}
