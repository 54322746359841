.treasury-modal {
  width: 100%;
  display: flex;

  .deal-treasury,
  .roles-config,
  .ownership-config {
    margin-top: 1em;
  }

  &__content {
    background-color: white;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  &__content-form {
    padding: 0em;
    margin-bottom: 2em;
  }
}

form {
  width: 100%;
}
