.social-network {
  display: flex;
  flex-direction: row;
  position: relative;
  .MuiFormLabel-root {
    padding-left: 50px;
  }

  .MuiInputLabel-shrink {
    padding-left: 50px;
  }

  .MuiInputBase-input.MuiFilledInput-input {
    padding-left: 50px;
  }

  &__icon {
    position: absolute;
    top: 50px;
    left: 20px;
    z-index: var(--z-10);
    width: 20px;
    height: 20px;
  }

  &__cancel-icon {
    position: absolute;
    cursor: pointer;
    top: 21px;
    right: -10px;
    z-index: var(--z-40);
  }
}
