.notification-item {
  padding: 1em 1.2em;

  &:hover {
    background-color: #bbbbbb21;
    cursor: pointer;
  }
  img {
    width: 32px;
    height: 32px;
    border-radius: 32px;
  }

  &__info {
    display: flex;
    gap: 1em;
    align-items: center;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 0.2em;
  }

  &__message {
    font-size: 0.875em;

    span {
      font-weight: 700;
    }
  }

  &__time {
    font-size: 0.8em;
  }
}
