.club-details-layout {
  display: flex;
  align-items: flex-start;
  height: 100vh;
  padding-top: 80px;
  &__content {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;

    &-components {
      overflow-y: scroll;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
