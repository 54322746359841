.home-summary {
  display: flex;
  flex-direction: column;
  gap: 1em;

  &__cards {
    display: flex;
    justify-content: space-between;
    gap: 2em;

    &-club {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1em;

      &:hover {
        cursor: pointer;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      gap: 1em;
    }

    &-claim {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1em;

      &:last-child {
        padding-bottom: 0;
      }
    }

    &__kyc {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      &-content {
        display: flex;
        flex-direction: column;
        gap: 1.5em;
        align-items: flex-start;

        p {
          color: var(--grey-300);
          font-size: 0.875em;
        }
      }

      &-verified {
        font-size: 0.875em;

        span {
          font-weight: 700;
        }
      }
    }
  }
}
