.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  font-size: 1.25em;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 100px;
    padding: 10px;
    width: 48px;
    height: 48px;
  }

  .active {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--grey-500);
    color: white;
    border-radius: 100px;
    padding: 10px;
    width: 48px;
    height: 48px;
  }

  :first-child {
    background-color: transparent;
  }

  :last-child {
    background-color: transparent;
  }
}
