.basic-table {
  color: var(--grey-500);
  cursor: default;

  img {
    vertical-align: middle;
    padding-left: 4px;
    cursor: pointer;
  }

  th {
    text-align: left;
    padding: 0 !important;
    color: var(--grey-500);
  }

  td {
    color: var(--grey-500);
  }
}

th.MuiTable-root {
  font-family: "Manrope";
}

th.css-1ygcj2i-MuiTableCell-root {
  border-bottom: 1px solid var(--grey-008);
  font-weight: 700;
  font-family: "Manrope";
  color: var(--grey-500);
  padding: 1em 0 1em 0 !important;
}

td.MuiTableCell-sizeMedium {
  border: none;
  padding: 1em 0;
}

td.MuiTableCell-body td.MuiTableCell-sizeMedium {
  color: var(--grey-500);
}
