.treasury-dropdown {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  position: relative;
  background-color: transparent;
  height: 0px;
  z-index: var(--z-40);
  padding: 0em 1.5em;
}

.multiple-tresuries-dropdown {
  background-color: white;
  padding: 1.25em 1.5em;
  border-radius: 12px;
  color: var(--grey-500);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
  border: 1px solid var(--white-200);
  position: relative;
  width: 100%;
  max-width: calc(1440px - 3em);

  cursor: pointer;

  &__details {
    display: flex;
    align-items: center;
    gap: 0.5em;

    h6 {
      font-weight: 700;
      color: var(--grey-500);
      font-size: 1em;
    }

    p {
      color: var(--grey-200);
      font-size: 0.875em;
    }

    &-flex {
      font-size: 0.875em;
      display: flex;
      align-items: center;
      gap: 0.5em;

      img {
        width: 16px;
      }
    }
  }

  &__options {
    display: flex;
    align-self: flex-start;
    align-items: center;
    gap: 0.5em;

    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  &__table {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2em;
    justify-items: center;
    align-items: flex-start;

    &-column {
      display: flex;
      flex-direction: column;
      row-gap: 0.25em;
    }

    h5 {
      font-weight: 400;
      color: var(--grey-300);
      font-size: 0.75;
    }

    &-value {
      display: flex;
      gap: 4px;

      &-currency {
        font-weight: 700;
        font-size: 1.25em;
      }

      &--grey {
        color: var(--grey-200);
        font-weight: 700;
        font-size: 1.25em;
      }
    }
  }
}

.multiple-treasuries-dropdown-list {
  position: absolute;
  background-color: transparent;
  z-index: var(--z-50);
  overflow: auto;
  max-height: 384px;
  width: calc(1438px - 3em);
  top: 90px;
  left: 0;
  box-shadow: 10px 10px 40px 0px var(--grey-008);

  :first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  :last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}
