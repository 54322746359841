.add-member-form {
  padding: 1em 0;
  color: var(--grey-500);

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 56px;
      height: 56px;
      margin-top: 0.5em;
      border-radius: 50%;
    }

    h2 {
      font-size: 1em;
      padding: 1em 0 1.5em;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    margin: 1em 0;
  }

  &__csv-input {
    display: none;
  }

  &__fields {
    position: relative;
    display: flex;
    padding: 0.35em;

    img {
      position: absolute;
      top: -7px;
      right: -10px;
      z-index: var(--z-10);
      background-color: white;
      padding: 2px;
    }
  }

  &__share {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;
    padding-bottom: 1.5em;

    p {
      font-size: 0.875em;
    }

    &-flex {
      display: flex;
      gap: 0.5em;

      div {
        display: flex;
        padding: 1em;
        border-radius: 50%;
        cursor: pointer;

        img {
          width: 18px;
        }
      }
    }
  }

  &__chip {
    padding-bottom: 0.5em;
  }
}
