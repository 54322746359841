.label-card {
  background-color: var(--white-100);
  border: 1px solid var(--grey-016);
  border-radius: 0.5em;
  padding: 10px;
  margin: 0.5em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__label {
    font-size: 0.75em;
    margin-bottom: 0.5em;
    color: #9a9a9a;
  }

  &__description {
    font-size: 1em;
    font-weight: 600;
  }

  img {
  }
}
