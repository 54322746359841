.cancel-reasons {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    padding: 1.5em 0;
  }

  &__notice {
    font-size: 0.875em;
    color: var(--grey-300);
  }

  &__other {
    font-size: 0.875em;
    color: var(--grey-500);
    font-weight: 700;
  }

  &__options {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 0.5em;
  }

  &__option {
    display: flex;
    height: 3.5em;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--grey-100);
    border-radius: 12px;
    padding: 0.625em 1em;

    p {
      color: var(--grey-300);
      font-size: 1em;
    }
  }
}
