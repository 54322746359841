.clubs-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: transparent;
  height: 0px;
  z-index: var(--z-20);
  margin: 2em 0em;

  &__menu {
    background-color: white;
    border-radius: 12px;
    color: var(--grey-500);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--white-200);
    position: relative;
    width: 100%;
    max-width: calc(1440px - 3em);
  }

  &__active {
    display: flex;
    align-items: center;
    gap: 1em;
    padding: 1.25em 1.5em;

    img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      object-fit: cover;
    }

    h6 {
      font-weight: 700;
      color: var(--grey-500);
      font-size: 1em;
    }

    p {
      color: var(--grey-200);
      font-size: 0.75em;
    }
  }

  &__options {
    margin-right: 1.5em;
  }

  &__list {
    position: absolute;
    background-color: white;
    z-index: var(--z-20);
    overflow: auto;
    max-height: 384px;
    width: calc(1438px - 3em);
    top: 90px;
    left: 0;
    box-shadow: 10px 10px 40px 0px var(--grey-008);
    display: flex;
    flex-direction: column;
    border-radius: 12px;
  }

  &__details {
    display: flex;
    align-items: center;
    gap: 1em;
    padding: 1.25em 1.5em;
    border: 1px solid var(--white-200);

    img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      object-fit: cover;
    }

    h6 {
      font-weight: 700;
      color: var(--grey-500);
      font-size: 1em;
    }

    p {
      color: var(--grey-200);
      font-size: 0.75em;
    }

    &:hover {
      background-color: var(--light-background-color);
      cursor: pointer;
    }
  }
}
