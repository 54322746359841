.configuration-changes-proposal {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  position: relative;

  p {
    font-size: 0.875em;
  }

  &__configuration-type {
    margin-top: 1em;
    display: flex;
  }
}
