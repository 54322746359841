.create-proposal {
  display: flex;
  flex-direction: column;
  padding: 1.5em 0 0;
  gap: 1.5em;
  color: var(--grey-500);

  h3 {
    font-size: 0.875em;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    position: relative;
  }
}
