.create-deal {
  height: 100vh;
  width: 100%;
  display: flex;

  &__panel {
    height: 100%;
    width: 40%;
    padding: 2em 2em 6em 2em;
    background: var(--light-background-color);
  }

  &__content {
    background-color: white;
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__content-form {
    height: 100%;
    padding: 0 12em;
    overflow-y: scroll;
    margin-bottom: 2em;
  }

  &__steps {
    display: flex;
    justify-content: space-between;
    padding: 1em 2em;
    gap: 2em;
    align-items: center;

    button {
      background-color: transparent;
      color: black;
      border-radius: 50%;
      transition: all 0.15s ease-out;
      border: none;
      border: 1px solid black;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
        background-color: var(--grey-100);
      }
    }
  }

  &__step-counter {
    color: var(--grey-300);
    font-size: 1em;
  }
}

form {
  width: 100%;
}
