.deal-treasury {
  width: 100%;
  margin-top: 5em;

  &__label {
    font-size: 0.8em;
    color: var(--grey-400);
    font-weight: 700;
  }

  &__config {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0.5em;
  }
}
