.create-fundraise-form {
  padding: 1.5em, 0;

  &__treasury {
    display: grid;
    grid-template-columns: 65% 35%;
    position: relative;

    img {
      position: absolute;
      top: -7px;
      right: -10px;
      z-index: var(--z-10);
      background-color: white;
      padding: 2px;
      cursor: pointer;
    }
  }

  &__call-to-action {
    margin-bottom: 2em;
  }

  &__custom-actions {
    display: flex;
    justify-content: center;
    margin-bottom: 2em;
  }

  h5 {
    font-weight: 300;
    color: var(--grey-300);
    font-size: 0.875em;
    margin: 1em 0;
  }

  h2 {
    margin: 1em 0;
    padding: 0;
    font-size: 1em;
  }
}
