.default-explorer {
  .css-ebu9hn-MuiFormControl-root .MuiInputBase-root .MuiFilledInput-input {
    display: flex;
    align-items: center;
    padding: 1em 2em;
    border: none;
  }

  .css-ebu9hn-MuiFormControl-root .MuiInputBase-root {
    background-color: white;
    border: none;
    border-radius: 0px;
    font-size: 16px;
    height: 56px;
    border-bottom: 1px solid var(--white-100);
  }

  &__item {
    display: flex;
    gap: 0.5em;
    align-items: center;

    &-logo {
      width: 24px;
      height: 24px;
    }
  }
}
