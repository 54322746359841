.tokens {
  &__headline {
    font-size: 1em;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1em;
  }
}
