.navbar {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 2em;
  background-color: white;
  height: 80px;
  z-index: var(--z-50);
  position: fixed;

  &__logo {
    width: 120px;
    height: 100%;
    object-fit: contain;
    float: left;
  }

  &__items {
    display: flex;
    height: 100%;
    gap: 1.5em;

    &-list {
      display: flex;
      gap: 1em;

      a {
        color: var(--grey-500);
        font-weight: 700;
        display: flex;
        align-items: center;
        font-size: 0.875em;
        border-bottom: 1px solid transparent;
        transition: all 0.15s ease-out;

        &:hover {
          color: var(--grey-500);
          background-color: var(--white-100);
          border-bottom: 1px solid var(--grey-500);
        }
      }

      .active {
        color: var(--primary-color);
        border-bottom: 1px solid transparent;

        &:hover {
          color: var(--primary-color);
          background-color: transparent;
          border-bottom: 1px solid var(--primary-color);
        }
      }
    }

    &-options {
      display: flex;
      align-items: center;
      gap: 1em;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      padding: 0.5em;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        background-color: var(--white-100);
      }
    }
  }

  &__items__icon {
    background-color: transparent;
    border: 0px;
    position: relative;
  }

  &__notification-flag {
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 7px;
    background-color: var(--green-400);
    top: 8px;
    right: 10px;
  }

  &__wallet {
    display: flex;
    align-items: center;
    padding: 0.5em 0.75em;
    background-color: transparent;
    border: 1px solid var(--grey-016);
    font-size: 0.875em;
    transition: all 0.3s ease-out;
    border-radius: 0.75em;
    gap: 0.25em;

    &:hover {
      background-color: var(--white-100);
    }
  }

  &__notifications {
    position: relative;
  }

  &__notifications-wrapper {
    background-color: initial;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}
