.tab-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 2em 1.5em;
  align-items: center;

  ul {
    display: flex;
    gap: 1em;
  }

  &__tab {
    color: var(--grey-200);
    font-weight: 700;
    font-size: 0.875em;
    cursor: pointer;

    &--active {
      cursor: pointer;
      color: var(--grey-500);
      font-weight: 700;
      font-size: 0.875em;
      border-bottom: 1px solid var(--grey-500);
      padding-bottom: 0.75em;
      transition: color 0.5s ease-out;
    }
  }
}
