.proposal-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-flex {
    display: flex;
    align-items: center;
    gap: 0.5em;

    span {
      font-size: 0.75em;
    }
  }
}
