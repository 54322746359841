.create-allocation {
  border: 1px solid var(--white-200);
  border-radius: 12px;
  padding: 1.5em;
  margin-bottom: 2em;

  &__custom {
    display: flex;
    flex-direction: column;
    &-actions {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  &__multi-toggle {
    display: flex;
  }

  &__grid {
    width: 100%;
    display: grid;
    grid-template-columns: 65% 35%;
  }

  h5 {
    font-weight: 300;
    color: var(--grey-300);
    font-size: 0.875em;
    margin: 1em 0;
  }
  h2 {
    margin: 1em 0;
    padding: 0;
  }
}
