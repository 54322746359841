.treasury-configuration-modal {
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;

  &__governance-rules {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    h3 {
      font-size: 0.85em;
    }
  }
  input {
    border: 1px solid;
  }
}
