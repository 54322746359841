.invite-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
  padding: 0.75em 2em;
  background-color: var(--white-200);
  width: 100%;

  &__flex {
    display: flex;
    gap: 0.5em;
    align-items: center;

    img {
      width: 1.25em;
    }

    p {
      color: var(--grey-500);
      font-size: 0.75em;
    }
  }

  &__reject-button {
    background-color: transparent;
    color: var(--grey-500);
    padding: 0.5em 0.75em !important;
    font-size: 0.875em;
    display: flex;
    align-items: center;
    transition: all 0.15s ease-out;
    padding: 0.75em 1em;
    border-radius: 100px;
    font-size: 0.875em;
    border: none;
    border: 1px solid transparent;

    &:hover {
      cursor: pointer;
      background-color: var(--grey-008);
      border: 1px solid var(--grey-008);
    }
  }
}
