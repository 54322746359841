.treasury-main {
  max-width: 1440px;
  width: 100%;
  padding: 2.5em 1.5em;

  &__fundraise {
    background-color: #eff9f6;
    border-radius: 12px;
    padding: 2em;
    border: 1px solid var(--grey-100);
  }
}
