.social-networks-fields {
  &__optional {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1em 0;
  }
  &__add-networks {
    p {
      font-size: 0.875em;
      color: var(--grey-500);
      font-weight: 400;
    }
  }

  &__icons {
    display: flex;
    gap: 0.5em;
    align-items: center;
    justify-content: end;

    &-img {
      display: flex;
      cursor: pointer;
      border: 1px solid var(--white-300);
      border-radius: 50px;
      width: 2.2em;
      height: 2.2em;
      padding: 0.6em;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: var(--white-100);
        transition: all 0.15s ease-in-out;
      }
    }
  }
}
