.details-card {
  background-color: white;
  border: 1px solid var(--grey-008);
  padding: 2em;
  border-radius: 0.75em;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  cursor: default;
  height: 335px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  h3 {
    font-size: 1em;
    margin-bottom: 2em;
  }

  &--chip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5em;

    h3 {
      margin-bottom: 0em;
    }
  }
}
