.required-field {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--grey-100);
  padding: 1em 0.5em;
  border-radius: 0.5em;
  margin: 0.5em 0;

  &--disabled {
    background-color: var(--white-100);
  }
}
