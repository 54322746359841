.overview-members {
  background-color: white;
  border: 1px solid var(--grey-008);
  padding: 2em;
  border-radius: 0.75em;
  display: flex;
  flex-direction: column;
  gap: 2em;
  justify-content: space-between;
  cursor: default;
  width: 100%;

  &__header {
    display: flex;
    align-items: center;

    h3 {
      font-size: 1em;
      margin-left: 1em;
    }
  }

  &__stats {
    display: flex;
    justify-content: space-between;

    h6 {
      font-size: 0.75em;
      color: var(--grey-300);
      font-weight: 400;
      padding-bottom: 4px;
    }

    p {
      font-weight: 700;
      font-size: 1em;
    }
  }
}

.member-circular-list {
  align-self: center;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  img {
    border-radius: 100%;
    border: 2px solid white;
  }

  &__main {
    width: 56px;
    height: 56px;
    margin: 0 auto;
    z-index: var(--z-40);
  }

  &__small {
    width: 32px;
    height: 32px;

    &--left {
      width: 32px;
      height: 32px;
      position: absolute;
      left: 19%;
      z-index: var(--z-10);
    }

    &--right {
      width: 32px;
      height: 32px;
      position: absolute;
      right: 19%;
      z-index: var(--z-10);
    }
  }

  &__medium {
    width: 40px;
    height: 40px;

    &--left {
      width: 40px;
      height: 40px;
      position: absolute;
      left: 25%;
      z-index: var(--z-20);
    }

    &--right {
      width: 40px;
      height: 40px;
      position: absolute;
      right: 25%;
      z-index: var(--z-20);
    }
  }

  &__large {
    &--left {
      width: 48px;
      height: 48px;
      position: absolute;
      left: 33%;
      z-index: var(--z-30);
    }

    &--right {
      width: 48px;
      height: 48px;
      position: absolute;
      right: 33%;
      z-index: var(--z-30);
    }
  }
}
