.progress-bar-circle {
  width: 100%;

  &__line {
    width: 100%;
    box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
    height: 4px;
    box-sizing: content-box;
    background: var(--grey-100);
    border-radius: 0.5em;
    margin-right: 2em;
    display: flex;
    &-circle {
      max-width: 100%;
      width: 100%;
      display: block;
      border-radius: 0.5em;
      background-color: transparent;
      position: relative;

      span {
        font-size: 0.75em;
        background-color: var(--white-300);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        position: absolute;
        top: -1.25em;
        z-index: var(--z-10);
      }
    }

    &--green {
      max-width: 100%;
      display: block;
      height: 100%;
      border-radius: 0.5em;
      background-color: var(--primary-color);
      overflow: hidden;
    }

    &--red {
      max-width: 100%;
      display: block;
      height: 100%;
      border-radius: 0.5em;
      background-color: var(--primary-color);
      overflow: hidden;
      right: 0;
      top: 0;
      background-color: var(--red-500);
    }

    &-info {
      display: flex;
      width: 100%;
      justify-content: space-between;

      p {
        font-size: 0.75em;
        color: var(--grey-200);
        padding-top: 2.5em;
      }
    }
  }
}
