.treasury-configuration {
  padding: 1em;
  border: 1px solid var(--grey-100);
  border-radius: 12px;

  &__headline {
    font-size: 0.875em;
  }
  &__fields {
    display: flex;
    gap: 0.5em;
    width: 100%;
  }
}
