.members {
  max-width: 1440px;
  width: 100%;
  padding: 1.5em;

  &-actions {
    display: flex;
    align-items: center;
    gap: 0.5em;

    p {
      position: relative;
      display: flex;
      gap: 0.25em;
      font-size: 0.875em;
      color: var(--grey-500);
      cursor: pointer;
      padding-left: 1em;
    }
  }

  h3 {
    font-size: 1em;
    color: var(--grey-500);
    padding: 2em 0 1em;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }
}
