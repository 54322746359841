.overview-treasury {
  background-color: white;
  border: 1px solid var(--grey-008);
  padding: 2em;
  border-radius: 0.75em;
  display: flex;
  flex-direction: column;
  gap: 2em;
  justify-content: space-between;
  cursor: default;
  width: 100%;

  &__header {
    display: flex;
    align-items: center;
    gap: 1em;

    h3 {
      font-size: 1em;
    }
  }

  &__amount {
    font-size: 1.25em;
    font-weight: 700;
    width: 100%;

    &--grey {
      color: var(--grey-200);
    }

    &--thin {
      font-size: 0.6em;
      font-weight: 400;
      color: var(--grey-300);
      margin-top: 4px;
    }
  }

  &__owned {
    display: flex;
    justify-content: space-between;
    gap: 2em;

    h6 {
      font-size: 0.75em;
      color: var(--grey-300);
      font-weight: 400;
      padding-bottom: 4px;
    }

    p {
      font-weight: 700;
      font-size: 1em;
    }

    span {
      color: var(--grey-200);
    }

    &-tokens {
      display: flex;
      gap: 2em;
    }
  }

  &__button {
    align-self: flex-start;
  }
}
