.chip-v2 {
  border-radius: 100px;
  padding: 0.75em;
  background-color: var(--white-100);
  border: 1px solid var(--grey-100);
  color: var(--grey-300);
  cursor: pointer;
  &--active {
    background-color: white;
    color: var(--grey-500);
    border: 1px solid var(--grey-500);
  }
}
