.deal-configuration {
  width: 100%;
  margin-top: 5em;

  &__fundraise-model {
    width: 100%;

    div {
      width: 100%;
      display: flex;
    }
  }
  &__fundraise-model-label {
    font-size: 0.8em;
    color: var(--grey-400);
    font-weight: 700;
  }

  &__fundraise-model-toggle {
    margin: 0.5em 0;
  }
}
