.member-card {
  display: flex;
  justify-content: space-between;
  padding: 2em;
  background-color: white;
  border-radius: 12px;
  border: 1px solid var(--light-background-color);
  transition: all 0.15s ease-in;
  cursor: pointer;

  &__name {
    display: flex;
    gap: 1em;
    align-items: center;
    color: var(--grey-500);

    h4 {
      font-size: 1em;
    }

    p {
      font-size: 0.75em;
    }
  }

  &__all {
    display: flex;
    gap: 2em;
    justify-content: flex-start;
    align-items: center;

    h6 {
      color: var(--grey-500);
      margin-bottom: 4px;
      font-size: 0.75em;
    }

    p {
      color: var(--grey-500);
      font-size: 0.875em;
    }

    &-box {
      display: flex;
      gap: 2em;
      align-items: center;
      justify-content: unset;

      &-actions {
        display: flex;
        align-items: center;
        gap: 1em;

        img {
          cursor: pointer;
        }
      }
    }
  }

  &__date {
    display: flex;
    align-items: center;
    gap: 2em;

    h6 {
      color: var(--grey-500);
      margin-bottom: 4px;
      font-size: 0.75em;
    }

    p {
      color: var(--grey-500);
      font-size: 0.875em;
    }
  }

  &:hover {
    background-color: var(--white-100);
    border: 1px solid var(--white-300);
  }

  &__all-box-actions {
    button {
      border: 0px;
      background-color: transparent;
    }
  }
}
