.allocation-item {
  font-size: 0.875em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2em 1em;
  margin-top: 0.6em;
  border: 1px solid var(--grey-100);
  border-radius: 6px;

  &__currency {
    color: var(--grey-200);
  }
  &__amount,
  &__adress {
    font-weight: bold;
  }
  &__date {
    font-size: 0.875em;
    margin-right: 0.5em;
  }

  &__options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-icon-openNew {
      cursor: pointer;
      visibility: hidden;
    }
  }

  &:hover {
    background-color: var(--light-background-color);
    transition: all 0.15s ease-out;

    .allocation-item__options-icon-openNew {
      visibility: visible;
      transition: all 0.15s ease-in;
    }
  }
}
