.create-deal-panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  justify-content: flex-end;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  &__logo {
    width: 10em;
    cursor: pointer;
  }

  &__title {
    font: bold;
    color: var(--grey-500);
  }

  &__description {
    font-size: 1.25em;
    color: var(--grey-300);
    width: 100%;
  }
}
