.preview-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.85em;
  font-weight: 600;
  margin: 0.5em 0;

  button {
    background-color: transparent;
    border: 0px;
  }
}
