.overview {
  max-width: 1440px;
  width: 100%;
  min-height: 100%;
  padding: 2.5em 1.5em 1.5em;

  &__title {
    padding: 2em 0;
    font-size: 1em;
  }

  &__no-data {
    background-color: white;
    width: 100%;
  }
}

.overview-cards {
  display: flex;
  justify-content: space-evenly;
  gap: 2em;
}

.overview-proposal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

//TODO: add slider
.overview-proposals {
  display: flex;
  gap: 2em;
  row-gap: 2em;
  overflow-x: auto;

  h2 {
    font-size: 1em;
  }
}

.overview-vault {
  display: flex;
  flex-direction: column;
  padding-bottom: 5em;
  gap: 2em;

  &__button {
    align-self: center;
  }
}

.overview-vault-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1.5em;
}
