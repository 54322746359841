.club-applilcation-form {
  &__basic-info {
    h5 {
      font-size: 0.875em;
      color: var(--grey-300);
      font-weight: 400;
      margin-top: 1.5em;
    }
  }
  &__subtitle {
    font-weight: 700;
    font-size: 0.875em;
    padding: 0;
    margin-top: 1.5em;
  }
}
