.add-member-form {
  padding: 1em 0;
  color: var(--grey-500);

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 56px;
      height: 56px;
      margin-top: 0.5em;
    }

    h2 {
      font-size: 1em;
      padding: 1em 0 0;
    }

    h3 {
      font-size: 0.75em;
      padding: 0.5em 0 1.5em;
      font-weight: 400;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    margin: 1em 0;
  }

  &__fields {
    position: relative;
    display: flex;
    padding: 0.35em;
    gap: 8px;
  }

  &__description {
    padding: 0.35em;
    color: var(--grey-300);
    font-size: 0.875em;
    margin-bottom: 1.5em;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    column-gap: 0.5em;

    h2 {
      font-size: 0.875em;
      color: var(--grey-500);
    }
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  &__chip {
    margin-top: 1.5em;
    padding-bottom: 0.5em;
  }
}
