.currency-chip {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  padding: 2px 8px;
  border-radius: 100px;
  color: white;
  font-size: 0.875em;
  width: fit-content;
}
