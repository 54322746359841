.past-fundraise {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--grey-100);
  padding: 1.2em;
  margin-bottom: 0.5em;
  font-size: 0.875em;
  border-radius: 5px;

  &__description {
    display: inline;
  }
  &__title,
  &__totaly_raised,
  &__currency {
    font-weight: bold;
  }

  &__currency {
    color: var(--grey-200);
  }

  &__status {
    padding: 0.1em 0.3em;
    border-radius: 5px;
    background-color: var(--green-100);
    &--finished {
      background-color: var(--red-100);
    }
  }
  &__date {
    font-size: 0.875em;
    margin-right: 0.5em;
  }

  &__options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-icon-openNew {
      cursor: pointer;
      visibility: hidden;
    }
  }

  &:hover {
    background-color: var(--light-background-color);
    transition: all 0.15s ease-in-out;
    .past-fundraise__options-icon-openNew {
      visibility: visible;
    }
  }
}
