.vault-card {
  background-color: white;
  border: 1px solid var(--grey-008);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  transition: all 0.15s ease-out;

  img {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    object-fit: cover;
    aspect-ratio: 1;
    width: 100%;
    position: relative;
  }

  &__details {
    padding: 1.5em;
    gap: 4px;

    h3 {
      font-size: 1em;
      font-weight: 700;
    }

    p {
      color: var(--grey-200);
      font-size: 0.75em;
    }

    &-price {
      font-weight: 700;
      padding-top: 1em;
      font-size: 0.875em !important;
      color: var(--grey-500) !important;

      span {
        font-weight: 600;
        color: var(--grey-200);
      }
    }
  }

  &-buttons {
    position: relative;

    button {
      position: absolute;
      top: -3.875em;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      transform: translateX(-50%);
      padding: 0.75em 1em;
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      z-index: 24;
    }
  }

  &-actions {
    position: absolute;
    display: flex;
    top: 0.5em;
    right: 0.5em;
    gap: 2px;

    img {
      border-radius: 50%;
      background-color: white;
      height: 1em;
    }
  }

  &:hover {
    box-shadow: 10px 10px 40px var(--grey-008);
  }
}
