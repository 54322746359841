.upload-photo {
  &__image {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }

  &__image-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--grey-100);
    border-radius: 50%;
    cursor: pointer;
    width: 100px;
    height: 100px;
    position: relative;
  }

  &__avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  &__removeIcon {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  &__data {
    color: var(--grey-200);
    font-size: 0.8em;
    line-height: 22px;
    text-align: center;
  }
}
