.basic-club-details-card {
  display: flex;
  gap: 1em;
  align-items: center;
  font-size: 1em;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1;
    border-radius: 50%;
  }

  &__text {
    font-size: 1em;

    div {
      display: flex;
      gap: 4px;
    }

    p {
      font-size: 0.75em;
      color: var(--grey-300);
    }

    span {
      color: var(--grey-200);
      font-size: 0.75em;
    }
  }
}
