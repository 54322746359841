.sticky-header {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 1em 2em;
  border-bottom: 1px solid var(--light-background-color);
  transition: all 0.15s ease-out;
  cursor: default;

  &__deal-details {
    display: flex;
    align-items: center;
    gap: 0.5em;

    h2 {
      font-size: 1.175em;
      color: var(--grey-500);
    }
  }

  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__description {
    display: flex;
    gap: 0.25em;
    font-size: 0.75em;
    color: var(--grey-500);
  }

  &__options {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;

    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    &-divider {
      border-left: 2px solid var(--white-300);
      height: 40px;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 0.5em;
    border-radius: 50%;
    &:hover {
      background-color: var(--white-100);
    }
  }
}
