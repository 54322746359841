.token-chain {
  display: flex;
  position: relative;

  img {
    width: 24px;
    height: 24px;
    border-radius: 24px;

    &:first-child {
      margin-left: 0;
    }
    margin-left: -5px;
  }

  p {
    margin-left: 6px;
    font-size: 1em;
    font-weight: 700;
  }
}
