.proposals-item-skeleton {
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  width: 100%;
  background-color: var(--white-300);
  border-radius: 20px;
  padding: 1em 2em;
  margin-bottom: 1em;

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__basic-info {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media (max-width: 769px) {
      flex-direction: column;
    }
  }

  &__status-and-link {
    display: flex;
    gap: 0.5em;
    align-items: center;
  }
}
