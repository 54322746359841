.deposit-item {
  font-size: 0.875em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2em 1em;
  margin-top: 0.6em;
  border: 1px solid var(--grey-100);
  border-radius: 6px;

  &__description {
    position: relative;
    &::after {
      content: "";
      right: -2em;
      top: 0.5em;
      position: absolute;
      z-index: var(--z-40);
      width: 8px;
      height: 10px;
      background-image: url("../../../assets/arrow.png");
      background-size: contain;
      margin-right: 0.5em;
    }

    &--withdrawn {
      &::after {
        content: " ";
        right: -2em;
        top: 0.5em;
        position: absolute;
        z-index: var(--z-40);
        width: 8px;
        height: 10px;
        background-image: url("../../../assets/arrow_down_alt.png");
        background-size: contain;
        margin-right: 0.5em;
      }
    }
  }

  &__address,
  &__amount,
  &__currency {
    font-weight: bold;
  }

  &__amount {
    color: var(--green-400);

    &--withdrawn {
      color: var(--red-status);
    }
  }
  &__currency {
    color: var(--grey-200);
  }
  &__date {
    font-size: 0.875em;
    margin-right: 0.5em;
  }

  &__options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-icon-openNew {
      margin-right: 0.5em;
    }
    &-icon-openNew,
    &-icon-cancel {
      cursor: pointer;
      visibility: hidden;
    }
  }

  &:hover {
    background-color: var(--light-background-color);
    transition: all 0.15s ease-out;
    .deposit-item__options-icon-cancel,
    .deposit-item__options-icon-openNew {
      visibility: visible;
      transition: all 0.15s ease-in;
    }
  }
}
