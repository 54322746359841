.home {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 8em;

  &__content {
    width: 100%;
    min-height: 100%;
    color: var(--grey-500);
    display: flex;
    flex-direction: column;
    gap: 2em;
    padding: 1.5em;
    max-width: 1440px;
  }

  h3 {
    font-size: 1em;
  }

  &-tables {
    display: flex;
    flex-direction: column;
    gap: 1.5em;

    .MuiTableCell-alignRight {
      text-align: center;
    }

    &__small-p {
      font-size: 12px;
      max-width: 180px;
    }

    &__buttons {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: flex-end;

      button {
        padding: 8px;
        border-radius: 100px;
        background-color: transparent;

        img {
          height: 20px;
          padding: 0;
        }
      }
    }

    &__align {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1em;
    }
  }
}

.currency-pill {
  background-color: var(--grey-500);
  padding: 4px 8px;
  border-radius: 100px;
  font-family: 700;
  color: white;
  width: max-content;
}

.progress-bar {
  &__text {
    text-align: end;
  }
}
