.multiple-treasuries-dropdown-card {
  background-color: white;
  padding: 1.5em 2em;
  color: var(--grey-500);
  display: grid;
  grid-template-columns: 50% 50%;
  cursor: default;
  border-bottom: 1px solid var(--grey-008);
  transition: all 0.15s ease-in-out;

  &__table {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2em;
    justify-items: center;
    align-items: flex-start;

    &-column {
      display: flex;
      flex-direction: column;
      row-gap: 0.25em;
    }

    h5 {
      font-weight: 400;
      color: var(--grey-300);
      font-size: 0.75;
    }

    &-value {
      display: flex;
      gap: 4px;

      &-currency {
        font-weight: 700;
        font-size: 1em;
      }

      &--grey {
        color: var(--grey-200);
        font-weight: 700;
        font-size: 1em;
      }
    }
  }
  &:hover {
    background-color: var(--light-background-color);
    cursor: pointer;
  }
}
