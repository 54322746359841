.home-stats {
  background-color: white;
  padding: 1.5em 2em;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__table {
    display: flex;
    gap: 2em;

    h4 {
      color: var(--grey-300);
      font-size: 0.75em;
      font-weight: 400;
    }

    p {
      font-weight: 700;

      span {
        color: var(--grey-200);
        font-weight: 400;
      }
    }
  }

  &__edit {
    display: flex;
    border: 1px solid var(--grey-500);
    border-radius: 12px;
    transition: all 0.15s ease-out;

    img {
      padding: 10px;
      border-radius: 12px;

      &:hover {
        background-color: var(--white-200);
      }
    }
  }
}
