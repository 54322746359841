.intro-deck {
  max-width: 1440px;
  width: 100%;
  color: var(--grey-500);
  padding: 1.5em;

  &__content {
    display: flex;
    justify-content: space-evenly;
    gap: 1em;
  }

  &__details-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1em;

    &-chip {
      align-self: center;
      justify-self: flex-end;
    }

    &-raised {
      padding-bottom: 4px;

      h6 {
        font-size: 0.75em;
        color: var(--grey-300);
        font-weight: 400;
        padding-bottom: 0.5em;
      }

      p {
        font-weight: 700;
        font-size: 1em;
        padding-bottom: 0.5em;
      }

      &--grey {
        color: var(--grey-200);
      }
    }

    &-stats {
      display: flex;
      gap: 1em;
    }

    &-date {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--white-300);
      padding-bottom: 0.5em;
      font-size: 0.875em;

      &--grey {
        color: var(--grey-300);
      }

      &:last-child {
        border-bottom: 1px solid transparent;
      }
    }
  }
}
