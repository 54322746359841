.overview-fundraise {
  background-color: white;
  border: 1px solid var(--grey-008);
  padding: 2em;
  border-radius: 0.75em;
  display: flex;
  flex-direction: column;
  cursor: default;
  width: 100%;
  gap: 1.5em;

  &__header {
    display: flex;
    align-items: center;
    gap: 1em;

    h3 {
      font-size: 1em;
    }
  }

  &__buttons {
    display: flex;
    gap: 0.5em;
  }

  &__no-data {
    margin-top: 2em;
    &-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 4em;
      img {
        width: 32px;
        height: 32px;
      }
    }
  }
}
