.overview-treasury-details {
  display: flex;
  align-items: center;
  gap: 1em;

  &-info {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      align-items: center;
      gap: 2px;
    }

    img {
      width: 1em;
      cursor: pointer;
      filter: contrast(60%);
    }
  }

  h6 {
    font-weight: 700;
    color: var(--grey-500);
    font-size: 1em;
  }

  p {
    color: var(--grey-200);
    font-size: 0.75em;
  }
}
