.multi-toggle {
  background: var(--white-100);
  text-align: center;
  color: var(--grey-300);
  width: 100%;
  cursor: pointer;
  padding: 1em 0.25em;
  border-top: 1px solid var(--grey-100);
  border-bottom: 1px solid var(--grey-100);

  p {
    width: 100%;
    font-size: 0.8em;
  }

  &--selected {
    background: var(--grey-100);
    color: var(--grey-400);
    font-weight: 700;
  }

  &--first {
    border-radius: 8px 0 0 8px;
  }

  &--last {
    border-radius: 0 8px 8px 0;
  }
}
