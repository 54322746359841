.input-field {
  width: 100%;
  height: 100%;
  gap: 0.5em;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0.5em 0;

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1em;
    width: 100%;
  }

  &__edit-icon {
    position: absolute;
    top: 35%;
    right: 2%;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }

  &__wrapper {
    width: 100%;
  }

  &__toggle-text {
    position: absolute;
    right: 2%;
    top: 30%;
  }

  &__toggle-label {
    font-size: 1em;
    color: black;
  }

  &__label {
    font-weight: bold;
    font-size: 0.8em;
    color: var(--grey-500);
  }

  &__error {
    color: var(--red-500);
    font-size: 0.7em;
    padding-left: 1em;
  }

  &__disabled {
    div {
      display: flex;
      position: relative;
      img {
        position: absolute;
        left: 95%;
        top: 30%;
      }
      input {
        background-color: transparent;
        padding: 1.5em;
        width: 100%;
        border: 1px solid var(--grey-100);
        border-radius: 8px;
      }
    }
  }

  &__toggle-content {
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid var(--grey-100);
    border-radius: 8px;
    padding-top: 1em;

    &--disabled {
      background-color: var(--white-100);
    }
  }

  &__toggle-heading {
    display: flex;
    margin-bottom: 1em;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;
  }

  &__toggle-expanded {
    display: flex;
    width: 100%;
  }

  &__toggle-button {
    position: absolute;
    left: 80%;
    top: 0%;
  }
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }

  &__badge {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    right: 1em;
    height: 54px;
    z-index: var(--z-10);
    font-size: 0.75em;
    font-weight: 700;
  }

  &__subtext {
    color: var(--grey-300);
    font-size: 0.75em;
    padding-left: 1em;
  }
}
