.user-image {
  img {
    border-radius: 100px;
    height: 40px;
    width: 40px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 1em;
    border-radius: 50%;
    cursor: pointer;
    height: 40px;
    width: 40px;
    color: var(--grey-500);

    p {
      font-size: 1em;
    }
  }
}
