.home-header {
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: flex-start;

  h2 {
    font-size: 1.25em;
  }

  &-image {
    position: relative;

    &-profile {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      object-fit: cover;
    }

    div {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      padding: 0.5em;
      background-color: white;
      border-radius: 50%;
      border: 1px solid var(--white-300);
    }

    &--no-image {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--white-300);
      width: 120px;
      height: 120px;
      border-radius: 50%;
      font-size: 1.25em;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: default;
    gap: 0;
  }

  &__wallet {
    display: flex;
    align-items: center;
    margin-top: -0.5em;

    p {
      color: var(--grey-300);
      font-size: 0.875em;
    }

    img {
      height: 1em;
      width: 1.1em;
    }
  }
}
