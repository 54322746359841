.fundraise-form {
  padding: 1em 0;
  color: var(--grey-500);

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 56px;
      height: 56px;
      margin-top: 0.5em;
      border-radius: 50%;
    }

    h2 {
      font-size: 1em;
    }

    p {
      padding: 0 0 1.5em;
      font-size: 0.8em;
    }
  }

  &__fields {
    position: relative;

    &-chips {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5em;
      right: 1em;
      height: 54px;
      z-index: var(--z-10);
    }
  }

  &__results {
    display: flex;
    gap: 0.5em;
    padding-top: 0.5em;

    &-box {
      width: 100%;
      display: flex;
      background-color: rgba(250, 250, 250, 1);
      border: 1px solid var(--grey-016);
      border-radius: 12px;
      justify-content: space-between;
      align-items: center;
      gap: 0.5em;
      padding: 10px 15px;

      h4 {
        color: var(--grey-200);
        font-size: 0.75em;
        font-weight: 400;
      }

      &--small {
        background-color: rgba(250, 250, 250, 1);
        border: 1px solid var(--grey-016);
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px 15px;
        width: 40%;

        h4 {
          color: var(--grey-200);
          font-size: 0.75em;
          font-weight: 400;
        }
      }
    }
  }
}
