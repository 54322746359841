.history-tabs {
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  &__past-fundraises,
  &__historical-deposits {
    cursor: pointer;
    color: var(--grey-200);
    font-size: 0.875em;
    margin-right: 0.4em;
    border-bottom: 2px solid white;
    &--open,
    &--open {
      border-bottom: 2px solid var(--grey-200);
      color: var(--grey-500);
      transition: all 0.15s ease-out;
    }
  }
}
