.otc-card {
  display: flex;
  padding: 1.5em 2em;
  border-radius: 12px;
  background-color: white;
  justify-content: space-between;
  transition: all 0.15s ease-out;
  border: 1px solid var(--light-background-color);

  &:last-of-type {
    margin-bottom: 2em;
  }

  div {
    display: flex;
    column-gap: 3.5em;
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: fit-content;

    h3 {
      font-weight: 400;
      font-size: 0.875em;
      color: var(--grey-300);
    }

    p {
      align-self: flex-end;

      span {
        color: var(--grey-200);
      }
    }

    &--actions {
      width: 103px;
    }
  }

  &--bold {
    font-weight: 700;
  }

  &:hover {
    background-color: var(--white-100);
    border: 1px solid var(--white-300);
  }
}
