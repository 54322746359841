.no-data {
  background-color: var(--white-200);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  height: 250px;
  border-radius: 12px;
  border: 1px solid var(--grey-008);
}
