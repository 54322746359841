.staking-card {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;
  border-radius: 12px;
  background-color: white;

  h2 {
    font-size: 1.25em;
  }

  h4 {
    font-size: 0.875em;
  }

  p {
    span {
      font-weight: 700;
    }
  }

  &__info {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    border: 1px solid var(--grey-016);
    border-radius: 12px;

    &-tab {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 1em 1.5em;
      border-right: 1px solid var(--grey-016);
      gap: 4px;

      &--last {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 1em 1.5em;
        border-right: none;
        gap: 4px;
      }
    }
  }

  &__total {
    display: flex;
    justify-content: space-between;
    padding: 0 1.5em;

    &-reward {
      display: flex;
      justify-content: space-between;
      width: 25%;
    }
  }
  &__deposit-cards {
    border-bottom: 1px solid var(--white-100);
    padding-bottom: 1em;
  }

  &-actions {
    display: flex;
    gap: 1em;
    justify-content: space-between;

    div {
      display: flex;
      gap: 1em;
    }
  }
}
