.sidebar-link {
  padding: 1em 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.15s ease-out;

  img {
    width: 24px;
    float: left;
  }

  p {
    position: absolute;
    left: 87px;
    font-size: 0.75em;
    width: 100%;
    z-index: var(--z-40);
  }
}

.sidebar-link--expanded {
  padding: 1em 1.5em;
  gap: 0.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 230px;
  transition: all 0.15s ease-out;

  img {
    width: 24px;
    float: left;
  }

  &-hide {
    rotate: 45deg;
  }

  p {
    position: relative;
    color: white;
    font-size: 0.875em;
  }

  &:hover {
    background-color: var(--grey-hover);
  }

  &-active {
    background-color: var(--primary-color);

    p {
      color: var(--grey-500);
    }

    img {
      filter: brightness(0);
    }

    &:hover p {
      filter: brightness(0);
    }

    &:hover img {
      filter: brightness(100);
    }

    &:hover div {
      background-color: var(--primary-color);

      img {
        filter: brightness(0);
      }
    }
  }
}

.hide {
  rotate: 45deg;
}

.active-sidebar-link {
  background-color: var(--primary-color);
  transition: all 0.15s ease-out;

  img {
    filter: brightness(0);
  }

  &:hover img {
    filter: brightness(100);
  }

  &:hover div {
    background-color: rgba(6, 215, 159, 0.577);

    img {
      filter: brightness(0);
    }
  }
}

p.active-sidebar-link-label {
  color: var(--grey-500);
}
