.treasury-member {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  padding: 1.5em;
  border: 1px solid var(--grey-100);
  border-radius: 8px;

  &__info {
    display: flex;
    align-items: center;
    gap: 1em;
    &-basic {
      display: flex;
      flex-direction: column;
    }
  }
  &__user-name {
    font-weight: bold;
    font-size: 1em;
  }
  &__role {
    font-size: 0.875em;
  }

  &__characteristics {
    display: flex;
    gap: 24px;
  }

  &__ownership,
  &__voting-power {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: 0.75em;

    span {
      font-weight: 400;
      font-size: 0.87em;
    }
  }
  &__options {
    display: flex;
    align-items: center;
    gap: 0.5em;
    visibility: hidden;
  }

  &:hover {
    background-color: var(--white-100);
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    .treasury-member__options {
      visibility: visible;
    }
  }
}
