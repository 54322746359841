.hover-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0.5em;
  border-radius: 50%;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: var(--white-100);
  }
}
