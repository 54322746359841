.proposals {
  max-width: 1440px;
  width: 100%;
  padding: 4.5em 1.5em 1.5em;
  color: var(--grey-500);

  &-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 2em 1.5em;

    ul {
      display: flex;
      gap: 1em;
      align-items: center;
    }

    &-link {
      color: var(--grey-200);
      font-weight: 700;
      font-size: 0.875em;
      cursor: pointer;
      padding-bottom: 0.75em;

      &--active {
        cursor: pointer;
        color: var(--grey-500);
        font-weight: 700;
        font-size: 0.875em;
        border-bottom: 1px solid var(--grey-500);
        padding-bottom: 0.75em;
        transition: color 0.5s ease-out;
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      gap: 0.5em;

      p {
        position: relative;
        display: flex;
        gap: 0.25em;
        font-size: 0.875em;
        color: var(--grey-500);
        cursor: pointer;
        padding-left: 1em;
      }
    }
  }

  &-cards {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
}
