@import "../../index.scss";

.browse-deals {
  width: 100%;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8em;

  &__wrapper {
    max-width: 1440px;
    width: 100%;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5em;

    @include lg {
      justify-content: center;
    }
  }

  &__search-sort {
    display: flex;
    gap: 1em;
    align-items: center;
  }
}
