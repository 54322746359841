.search-bar {
  position: relative;

  input[type="text"] {
    padding: 8px 12px;
    height: 40px;
    border-radius: 12px;
    border: 1px solid var(--grey-016);
    font-size: 0.875em;
    color: var(--grey-500);
    outline: none;
    width: 280px;

    &::placeholder {
      color: var(--grey-300);
    }
  }

  img {
    background-color: white;
    position: absolute;
    right: 0.4em;
    top: 0.3em;
    z-index: var(--z-20);
    padding: 0.5em;
  }
}
