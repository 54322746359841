.notification-modal {
  position: absolute;
  right: 0;

  border: 1px solid var(--grey-016);
  border-radius: 0.5em;
  min-width: 450px;
  background-color: white;

  &__header {
    padding: 1em 1.2em 0 1.2em;
  }

  &__items {
    height: 378px;
    overflow: auto;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey-016);
  }

  &__button {
    border: 0px;
    background-color: transparent;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    padding: 1em 0;

    &:hover,
    &--active {
      border-bottom: 1px solid var(--green-400);
    }
  }
  &__title {
    font-size: 0.875em;
  }

  &__tabs {
    display: flex;
    gap: 1em;
  }
}
