.main-button {
  background-color: var(--grey-500);
  color: white;
  padding: 0.75em 1em;
  border-radius: 100px;
  font-size: 0.875em;
  transition: all 0.15s ease-out;
  display: flex;
  align-items: center;
  gap: 4px;
  border: none;
  z-index: var(--z-20);
  &:disabled {
    &:hover {
      background-color: var(--grey-500);
    }
  }

  &:hover {
    cursor: pointer;
    background-color: var(--grey-300);
  }

  &--light {
    padding: 0.75em 1em;
    border: 1px solid var(--grey-500);
    border-radius: 100px;
    transition: all 0.15s ease-out;
    background-color: transparent;
    color: var(--grey-500);

    &:disabled {
      &:hover {
        background-color: transparent;
      }
    }
    &:hover {
      background-color: var(--white-300);
    }
  }

  &--bold-disabled {
    font-weight: 700;
    border-color: black;

    &:disabled {
      opacity: 1;
    }
  }
}
