.ownership-config {
  width: 100%;
  margin-top: 2em;

  &__title {
    font-weight: bold;
    font-size: 0.8em;
    color: var(--grey-500);
  }

  &__description {
    font-size: 0.875em;
    color: var(--grey-300);
    margin-top: 0.5em;
    width: 85%;
  }

  &__voting-power {
    position: relative;
    display: flex;
    width: 100%;

    img {
      position: absolute;
      top: -7px;
      right: -10px;
      z-index: 10;
      background-color: white;
      padding: 2px;
      cursor: pointer;
    }
  }

  &__role {
    width: 75%;

    .MuiInputBase-root {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
  }

  &__power {
    width: 25%;
    .MuiInputBase-root {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }
  }

  &__section {
    margin-bottom: 1em;
  }
}
