.fee-card {
  width: 100%;
  padding: 1em 1.5em;
  background: var(--green-100);
  color: var(--green-500);
  font-size: 0.8em;
  border-radius: 8px;
  margin: 1em 0;
  text-align: start;
}
