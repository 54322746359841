.chip {
  border-radius: 12px;
  padding: 0.75em;
  background-color: var(--green-100);
  color: var(--grey-500);
  font-size: 0.875em;
  border-color: transparent;
  cursor: default;
  width: fit-content;

  p {
    display: flex;
    gap: 0.5em;
    align-items: center;
    text-transform: capitalize;
  }
}
