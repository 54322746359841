.progress-bar {
  height: 4px;
  box-sizing: content-box;
  position: relative;
  background: var(--grey-100);
  border-radius: 0.5em;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  margin-right: 0;
  width: 100%;

  span {
    max-width: 100%;
    display: block;
    height: 100%;
    border-radius: 0.5em;
    background-color: var(--primary-color);
    position: relative;
    overflow: hidden;
  }
}
