.walletDropdown {
  width: 100vw;
  height: 100vh;
  z-index: var(--z-30);
  position: fixed;
  top: 0;
  left: 0;
  &__content {
    padding-bottom: 2em;
    background-color: white;
    width: 22em;
    border-radius: 12px;
    position: absolute;
    top: 4em;
    right: 1em;

    border: 1px solid var(--white-100);
  }

  &__title {
    font-size: 0.875em;
    padding: 1em;
  }

  &__wallets {
    padding: 1em;
    &-list {
      display: flex;
      flex-direction: column;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid var(--white-100);
      border-radius: 12px;
      padding: 0.5em;
    }

    &-description {
      display: flex;
      gap: 0.5em;
      font-size: 0.875em;
      span {
        font-weight: bold;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
    }
  }

  &__copy-icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  &__options {
    margin-top: 0.5em;

    &-list {
      display: flex;
      flex-direction: column;
    }

    &-option {
      padding: 1em 2em;
      border: 1px solid var(--white-100);
      cursor: pointer;
      &:hover {
        background-color: var(--white-100);
        transition: all 0.5s ease-in-out;
        cursor: pointer;
      }
    }

    &-list li:not(:first-child) {
      border-top: none;
    }
  }
}
