.home-tables-box {
  background-color: white;
  padding: 2em;
  border: 1px solid var(--grey-008);
  border-radius: 0.75em;
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 1em;
  min-height: 479px;

  &--bold {
    font-weight: 700;

    span {
      color: var(--grey-200);
    }
  }
}
