.amount-with-mint {
  &__subtext {
    color: var(--grey-300);
    font-size: 0.75em;
    padding-left: 1em;

    span {
      color: var(--grey-500);
      font-weight: 700;
    }
  }

  &__fields {
    width: 100%;
    display: grid;
    grid-template-columns: 75% 25%;
  }
}
