.proposal_table_row {
  display: flex;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid black;
    border-radius: 50%;
    background-color: transparent;

    :disabled {
      cursor: default;
    }

    &:hover {
      background-color: var(--grey-100);
    }
  }
}
