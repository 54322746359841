.actions-before-fundraise {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white-200);
  padding: 1.5em 0.75em;
  border-radius: 12px;

  &__info {
    display: flex;
    gap: 0.5em;
    font-size: 0.75em;
    img {
      width: 20px;
      height: 20px;
    }
  }

  &__actions {
    font-size: 0.75em;
  }
  margin-bottom: 1em;
}
