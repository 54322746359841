.treasury-info {
  margin-top: 2em;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2em;
  &__balance-container {
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__balance {
    color: var(--grey-500);
    font-weight: 700;
    font-size: 1em;
    margin-bottom: 0.6em;
    display: flex;
    justify-content: space-between;
  }
  &__balance-amount {
    font-size: 2.5em;
    font-weight: bold;
    display: flex;
    gap: 0.5em;

    span {
      color: var(--grey-200);
    }
  }

  &__description {
    display: flex;
    justify-content: space-between;
    padding-top: 2em;
    align-items: center;
  }

  &__actions {
    display: flex;
  }

  &__deposit,
  &__ownership,
  &__voting-power {
    display: flex;
    flex-direction: column;
    margin-left: 1.5em;
    color: var(--grey-300);
    font-size: 1em;
  }
  &__deposit-amount,
  &__ownership-amount,
  &__voting-power-amount {
    color: var(--grey-500);
    font-weight: bold;
    font-size: 1.25em;
    display: flex;
    gap: 0.5em;
  }

  &__currency {
    font-weight: bold;
    color: var(--grey-200);
    font-size: 1em;
  }
}
