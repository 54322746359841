.sort {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.25em;
  font-size: 0.875em;
  color: var(--grey-500);
  cursor: pointer;
  z-index: var(--z-20);
  transition: all 0.15s ease-out;

  p {
    display: flex;
  }

  ul {
    position: absolute;
    top: 2em;
    right: 0px;
    width: 200px;
    display: flex;
    flex-direction: column;
    gap: 0;
    border-radius: 0.5em;
    background-color: white;
    border: 1px solid var(--grey-100);

    li {
      padding: 0.875em;
      color: var(--grey-200);
      cursor: pointer;
      z-index: var(--z-10);
      transition: all 0.1s ease-in-out;

      &:hover {
        color: var(--grey-500);
        background-color: var(--white-100);
      }
    }
  }
}
