.club-configuration {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  color: var(--grey-500);
  padding: 1.5em 0;

  &__details {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 128px;
      height: 128px;
      border-radius: 50%;
      object-fit: cover;
      margin-bottom: 1em;
    }

    h2 {
      font-size: 1em;
    }

    p {
      font-size: 1em;
    }
  }

  &__verticals {
    h3 {
      padding-bottom: 0.5em;
      font-size: 0.875em;
    }

    div {
      display: flex;
      gap: 0.5em;
      font-size: 0.875em;
    }
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.5em;
    border-top: 1px solid var(--grey-100);
  }
}
