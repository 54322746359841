//FONTS
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

//DEFAULT
:root {
  //COLORS
  --light-background-color: #f3f3f3;
  --primary-color: #06d7a0;
  --green-400: #05b285;
  --green-100: rgba(6, 215, 160, 0.24);
  --grey-500: #242424;
  --grey-300: #6c6c6c;
  --grey-200: #9a9a9a;
  --grey-100: #e6e6e6;
  --grey-008: rgba(36, 36, 36, 0.08);
  --grey-016: rgba(36, 36, 36, 0.16);
  --grey-hover: #3d3d3d;
  --white-100: #f4f4f4;
  --white-200: #e9e9e9;
  --white-300: #dedede;
  --blue-500: #2c7ef8;
  --blue-400: #32b0f7;
  --blue-300: rgba(44, 126, 248, 0.24);
  --blue-100: rgba(238, 242, 248, 1);
  --red-500: #e26b6b;
  --red-100: rgba(215, 6, 6, 0.24);
  --red-status: #d70606;
  --purple-500: #6544e7;
  --purple-100: rgba(136, 6, 215, 0.24);
  --yellow-100: rgba(215, 169, 6, 0.24);
  //Z-INDEX
  --z-10: 10;
  --z-20: 20;
  --z-30: 30;
  --z-40: 40;
  --z-50: 50;
  --z-60: 60;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style-type: none;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "Manrope", sans-serif;
  background: var(--light-background-color);
  &::-webkit-scrollbar {
    display: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Manrope", sans-serif;
  text-align: start;
  color: var(-grey--500);
}

button {
  cursor: pointer;
  border-radius: 0.75em;
}

button[disabled] {
  opacity: 0.6;
}

input[type="radio"] {
  accent-color: #349b80;
  cursor: pointer;
}

p,
a,
li,
b,
input,
textarea,
span,
strong,
::placeholder,
button {
  font-family: "Manrope", sans-serif;
}

a {
  color: inherit;
  cursor: pointer;
}

.backdrop {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.icon-hover {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0.5em;
  border-radius: 50%;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: var(--white-100);
  }
}

//RESPONSIVE MIXINS
//TODO: adjust
$small-width: 425px;
$medium-width: 768px;
$large-width: 1121px;

@mixin sm {
  @media (max-width: #{$small-width}) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$medium-width}) {
    @content;
  }
}

@mixin lg {
  @media (max-width: #{$large-width}) {
    @content;
  }
}

//NOTIFICATIONS
div .notification-container {
  top: 6px;
  right: 13px;

  @media (max-width: 769px) {
    right: 0;
  }
}

div .notification {
  font-family: "Manrope", sans-serif;
  border-radius: 10px;
  font-size: 0.8em;
  box-shadow: unset;
  color: white;
  word-break: break-word;
}

div .notification .title {
  font-size: 0.9em;
}

div .notification .description {
  font-size: 0.9em;
}

div .notification-error {
  background-color: rgb(121, 7, 7);
}
