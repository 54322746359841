.vault {
  max-width: 1440px;
  width: 100%;
  padding: 4.5em 1.5em 1.5em;

  &__cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 1em;
  }
}
