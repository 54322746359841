.proposal-card {
  background-color: white;
  border-radius: 12px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  min-width: calc(50% - 1em);
  border: 1px solid var(--grey-008);
  cursor: pointer;
  transition: all 0.15s ease-out;

  &__stats {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 2em;
  }

  h2 {
    font-size: 1em;
    padding-bottom: 0.5em;
  }
}
