.fundraise-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__info {
    display: flex;
  }

  &__headline {
    font-size: 1.25em;
    display: flex;
    align-items: center;
    gap: 0.5em;
  }

  &__options {
    padding: 0.5em;
  }

  &__custom-allocation {
    font-size: 0.87em;
    display: flex;
    align-items: center;
    gap: 0.5em;

    img {
      cursor: pointer;
    }
  }
}
